<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="order-wrap main-section">
      <div class="query-form">
        <el-date-picker
          v-model="timesArr"
          size="large"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <div class="btns">
          <div class="ls-btns-block" @click="submitQuery">查询</div>
          <div class="ls-btns-block spin" @click="reset">重置</div>
        </div>
      </div>

      <Table
        v-if="!noData"
        :baseData="tableData"
        :tableHeader="tableHeader"
        :align="'center'"
        :currentPage="pageNo"
        :pageSize="pageSize"
        :total="total"
        :isShowPagination="true"
        @handleCurrent="handleCurrent"
      >
      </Table>
      <Empty v-else />
    </div>
  </section>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Empty from "@/components/common/Empty.vue";
import BreadCrump from "@/components/common/BreadCrump.vue";

import { orderQuery } from "@/api/interface/order.js";
import dayjs from "dayjs";

export default {
  components: {
    BreadCrump,
    Empty,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "订单管理" }],
      timesArr: [],
      tableData: [],
      tableHeader: this.$tableHeader.orderMan.orderList,
      noData: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {},
  methods: {
    handleCurrent(val) {
      this.pageNo = val;
      this.getDataList();
    },
    submitQuery() {
      this.getDataList();
    },
    reset() {
      this.pageNo = 1;
      this.timesArr = [];
    },
    getDataList() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      if (this.timesArr.length > 0) {
        params.baseData = dayjs(this.timesArr[0]).format("YYYY-MM-DD HH:mm:ss");
        params.endDate = dayjs(this.timesArr[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      orderQuery(params).then((data = {}) => {
        const { records = [], total } = data;
        if (!data || records.length == 0) {
          this.noData = true;
          return;
        } else {
          this.noData = false;
        }
        this.tableData = records;
        this.total = total;
      });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/el/_input.scss";
@import "@/scss/el/_table.scss";
.order-wrap {
  padding: 26px 26px 39px;
  background-color: #fff;
  margin-top: 16px;
  .query-form {
    margin-bottom: 21px;
  }
  .btns {
    display: inline-flex;
    margin-left: 24px;
  }
  .ls-btns-block {
    display: inline-flex;
    justify-content: center;
    margin-right: 18px;
  }
}
</style>