import * as API from "@/api/request";

const comUrl = "/v1/workbench/order/";

export function orderQuery(params) {
  return API.post(`${comUrl}query`, params);
}

// 充值
export const recharge = (data) => {
  return API.get(`/v1/workbench/order`, data);
};

// 汇率
export const gasRate = () => {
  return API.get(`${comUrl}gas-rate`);
};
