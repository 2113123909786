<template>
  <el-empty :description="item.description || '暂无数据可显示'"
    :image="img"
  ></el-empty>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      img: require("@/assets/images/common/icon_empty.png"),
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
:deep().el-empty {
  .el-empty__description {
    p {
      font-size: 14px;
      line-height: 20px;
      color: #858585;
    }
  }
  .el-empty__image {
    width: 71px;
    height: 80px;
  }
}
</style>